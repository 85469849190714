
















import { PackageIcon, PackageIconCss } from '@/models/packages/PackageIcon';
import { PackageIconBGColor } from '@/models/packages/PackageIconColor';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class PackageIconComponent extends Vue {
  private backgroundColors = PackageIconBGColor;
  private iconCss = PackageIconCss;
  private icons = PackageIcon;

  @Prop({ default: '-' })
  private packageFamily!: string;

  @Prop({ default: 0 })
  private backgroundColor!: number;

  @Prop({ default: 0 })
  private icon!: number;
}
