





































import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from './ModalComponent.vue';
import { ISkyNodeClient } from '../../models/skynode/SkyNodeClient';

@Component<SkynodeConfirmationModal>({
  components: {
    Modal
  }
})
export default class SkynodeConfirmationModal extends Vue {
  @Prop({ default: '' })
  readonly size!: string;
  private node!: ISkyNodeClient;
  private show = false;
  private incomingUpdate!: boolean;
  private resolve: Function = () => void 0;
  private reject: Function = () => void 0;
  private close() {
    this.show = false;
  }
  public open(node: ISkyNodeClient) {
    this.incomingUpdate = !node.allow;
    this.show = true;
    this.node = node;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    }).catch(() => {
      return null;
    });
  }
  private confirm() {
    this.node.allow = this.incomingUpdate;
    this.resolve(this.node);
    this.close();
  }
  private cancel() {
    this.reject(this.node);
    this.close();
  }
}
