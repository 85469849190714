




















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import PackageIcon from '@/components/PackageIconComponent.vue';
import { Sorter } from '@/util/Sorter';
import Navigation from '@/components/NavComponent.vue';
import {
  ISkyNodeClient,
  UpdateSkynodeAllowDto
} from '@/models/skynode/SkyNodeClient';
import { PackagePlatformLabels } from '@/models/packages/PackagePlatformType';
import TableSortArrowComponent from '@/components/TableSortArrowComponent.vue';
import SkynodeConfirmationModal from '../../components/modals/SkynodeConfirmationModalComponent.vue';

@Component({
  components: {
    PackageIcon,
    Navigation,
    TableSortArrowComponent,
    SkynodeConfirmationModal
  }
})
export default class SkyNodeCollectionPage extends Vue {
  readonly DEFAULT_NODES_ONLINE = '0 %';
  private packagePlatformLabel = PackagePlatformLabels;
  private selected: number[] = [];
  private checkAll = false;

  @Prop({ default: () => [] })
  private nodes!: ISkyNodeClient[];

  @Prop({ default: () => '' })
  private pageTitle!: string;

  public sorting: Sorter = new Sorter();

  async updateSkynodeAllowed(node: ISkyNodeClient) {
    const denySkynodeModal = this.$refs[
      'SkynodeConfirmationModal'
    ] as SkynodeConfirmationModal;

    try {
      const returnedNode = (await denySkynodeModal.open(
        node
      )) as ISkyNodeClient;

      const updateDto: UpdateSkynodeAllowDto = {
        id: node.id,
        allow: returnedNode.allow
      };

      await this.$store.dispatch('skyNodeModule/updateSkynodeAllow', updateDto);
    } catch (error) {
      return;
    }
  }

  private routeToNodeDetail(id: number) {
    this.$router.push({
      name: 'skynode.detail',
      params: { id: id.toString() }
    });
  }

  private getNodeIsOnline(node: ISkyNodeClient) {
    const currentDate = new Date();

    // Check for date threshold for offline time
    if (node.clientPing == undefined) {
      return false;
    }

    const pingDate = new Date(node.clientPing.date);
    const diffMs = currentDate.getTime() - pingDate.getTime();
    return diffMs / 60000 < 5; // 60000 = number of ms in a minute
  }

  private getNodeStatus(node: ISkyNodeClient) {
    if (!node.allow) {
      return 'Denied';
    }
    let status = this.getNodeIsOnline(node) ? 'Online' : 'Offline';
    if (node.clientPing != undefined) {
      const pingDate = new Date(node.clientPing.date);
      status += ' / ' + pingDate.toLocaleString();
    }
    return status;
  }

  private getNodesOnline() {
    if (this.nodes.length === 0) {
      return this.DEFAULT_NODES_ONLINE;
    }

    const onlineNodes = this.nodes.filter((node) => this.getNodeIsOnline(node))
      .length;
    return (onlineNodes / this.nodes.length) * 100 + ' %';
  }

  private getNodeLocation(node: ISkyNodeClient) {
    if (node.clientPing == undefined) {
      return '';
    }

    const nodeLocation =
      node.clientPing.latitude + ' / ' + node.clientPing.longitude;
    return nodeLocation;
  }

  private getPlatformAndOs(node: ISkyNodeClient) {
    const platformOs =
      this.packagePlatformLabel.get(node.platform) +
      ' / ' +
      node.operatingSystem;
    return platformOs;
  }

  // MOCK DATA
  private averageBandwidth = '4.5';
  private latestInstalledTotal = '100%';

  //handles the check all button
  private selectAllHandler() {
    this.checkAll = !this.checkAll;
    this.selected = [];
    if (this.checkAll) {
      this.selected = this.nodes.map((node) => node.id);
      this.checkAll = true;
    }
  }
  private updateCheckAll() {
    if (this.selected.length === this.nodes.map((node) => node.id).length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }
  }

  // Sorter methods
  private sortByNodeId() {
    this.sorting.sort((p) => p.id, this.nodes, 'id');
  }

  private sortByName() {
    this.sorting.sort((p) => p.name, this.nodes, 'name');
  }

  private sortByStatus() {
    this.sorting.sort((p) => this.getNodeStatus(p), this.nodes, 'status');
  }

  private sortByLocation() {
    this.sorting.sort((p) => this.getNodeLocation(p), this.nodes, 'location');
  }

  private sortByPlatformAndOs() {
    this.sorting.sort((p) => this.getPlatformAndOs(p), this.nodes, 'platform');
  }

  private sortByMacAddress() {
    this.sorting.sort((p) => p.macAddress, this.nodes, 'mac');
  }
}
