var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "container px-8 mx-auto h-4/6" }, [
    _c(
      "h1",
      { staticClass: "my-6 text-2xl font-bold", attrs: { id: "table-title" } },
      [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")]
    ),
    _c("div", { staticClass: "flex-row justify-center" }, [
      _c(
        "div",
        { staticClass: "flex p-6 space-x-8 bg-white border border-black" },
        [
          _c("div", {}, [
            _c("p", { staticClass: "text-3xl skyhub-text " }, [
              _vm._v(" " + _vm._s(this.latestInstalledTotal) + " ")
            ]),
            _c("p", { staticClass: "text-black skyhub-subtext" }, [
              _vm._v(" Latest Package Installed ")
            ])
          ]),
          _c("div", { staticClass: "border" }),
          _c("div", {}, [
            _c(
              "div",
              { staticClass: "flex items-baseline text-3xl skyhub-text" },
              [
                _vm._v(" " + _vm._s(this.averageBandwidth) + " "),
                _c("div", { staticClass: "p-1 text-sm text-black" }, [
                  _vm._v(" MBPS ")
                ])
              ]
            ),
            _c("p", { staticClass: "text-black skyhub-subtext" }, [
              _vm._v(" Avg. Bandwidth ")
            ])
          ]),
          _c("div", { staticClass: "border" }),
          _c("div", {}, [
            _c("p", { staticClass: "text-3xl skyhub-text " }, [
              _vm._v(" " + _vm._s(this.getNodesOnline()) + " ")
            ]),
            _c("p", { staticClass: "text-black skyhub-subtext" }, [
              _vm._v(" Nodes Online ")
            ])
          ])
        ]
      )
    ]),
    _c(
      "div",
      {
        staticClass:
          "flex px-8 pt-4 border-b border-l border-r border-black bg-gray-50"
      },
      [
        _c(
          "h2",
          {
            staticClass:
              "inline px-3 pb-2 font-bold border-b-4 border-green-500"
          },
          [_vm._v(" Nodes ")]
        ),
        _c("div", { staticClass: "flex flex-col items-end w-full" }, [
          _vm._v(" " + _vm._s(_vm.selected.length) + " selected ")
        ])
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "overflow-y-scroll border-b border-l border-r border-black h-5/6"
      },
      [
        _c(
          "table",
          {
            staticClass: "w-full table-auto",
            attrs: { "aria-describedby": "table-title" }
          },
          [
            _c("thead", [
              _c("tr", { staticClass: "text-left border-b" }, [
                _c(
                  "th",
                  {
                    staticClass: "sticky top-0 px-8 py-2 bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.checkAll,
                          expression: "checkAll"
                        }
                      ],
                      staticClass: "mr-4",
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.checkAll)
                          ? _vm._i(_vm.checkAll, null) > -1
                          : _vm.checkAll
                      },
                      on: {
                        click: _vm.selectAllHandler,
                        change: function($event) {
                          var $$a = _vm.checkAll,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.checkAll = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.checkAll = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.checkAll = $$c
                          }
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "sticky top-0 w-auto px-5 py-1 text-left bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByNodeId()
                          }
                        }
                      },
                      [
                        _vm._v(" Node ID "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "id" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "sticky top-0 w-auto px-5 py-1 text-left bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByName()
                          }
                        }
                      },
                      [
                        _vm._v(" Name "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "name" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "sticky top-0 w-2/12 px-5 py-1 text-left bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByStatus()
                          }
                        }
                      },
                      [
                        _vm._v(" Status / Last Online "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "status" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "sticky top-0 w-auto px-5 py-1 text-left bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByPlatformAndOs()
                          }
                        }
                      },
                      [
                        _vm._v(" Platform and OS "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "platform" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass:
                      "sticky top-0 w-auto px-5 py-1 text-left bg-gray-50",
                    attrs: { scope: "col" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "cursor-pointer",
                        on: {
                          click: function($event) {
                            return _vm.sortByMacAddress()
                          }
                        }
                      },
                      [
                        _vm._v(" Mac Address "),
                        _c("table-sort-arrow-component", {
                          attrs: { sorting: _vm.sorting, name: "mac" }
                        })
                      ],
                      1
                    )
                  ]
                ),
                _vm._m(0)
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.nodes, function(node, index) {
                return _c(
                  "tr",
                  {
                    key: index,
                    staticClass: "skyhub-table-row",
                    on: {
                      click: function($event) {
                        return _vm.routeToNodeDetail(node.id)
                      }
                    }
                  },
                  [
                    _c("td", { staticClass: "px-8 py-4 whitespace-no-wrap" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected,
                            expression: "selected"
                          }
                        ],
                        staticClass: "mr-4",
                        attrs: { type: "checkbox" },
                        domProps: {
                          value: node.id,
                          checked: Array.isArray(_vm.selected)
                            ? _vm._i(_vm.selected, node.id) > -1
                            : _vm.selected
                        },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                          },
                          change: [
                            function($event) {
                              var $$a = _vm.selected,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = node.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.selected = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.selected = $$c
                              }
                            },
                            function($event) {
                              return _vm.updateCheckAll()
                            }
                          ]
                        }
                      })
                    ]),
                    _c("td", { staticClass: "py-3" }, [
                      _c("div", { staticClass: "flex items-center" }, [
                        _c("p", { staticClass: "p-1 px-5" }, [
                          _vm._v(" " + _vm._s(node.id) + " ")
                        ])
                      ])
                    ]),
                    _c("td", { staticClass: "p-1 px-5" }, [
                      _c("div", { staticClass: "inline-block" }, [
                        _vm._v(" " + _vm._s(node.name) + " ")
                      ])
                    ]),
                    _c("td", { staticClass: "p-1 px-5" }, [
                      _vm._v(" " + _vm._s(_vm.getNodeStatus(node)) + " ")
                    ]),
                    _c("td", { staticClass: "p-1 px-5" }, [
                      _vm._v(" " + _vm._s(_vm.getPlatformAndOs(node)) + " ")
                    ]),
                    _c("td", { staticClass: "p-1 px-5" }, [
                      _vm._v(" " + _vm._s(node.macAddress) + " ")
                    ]),
                    _c("td", { staticClass: "p-1 px-5" }, [
                      _c(
                        "div",
                        {
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "p-1 bg-gray-200 border-2 rounded ",
                              attrs: { "data-cy": "skynode-permission-button" },
                              on: {
                                click: function($event) {
                                  return _vm.updateSkynodeAllowed(node)
                                }
                              }
                            },
                            [_vm._v(" Update ")]
                          )
                        ]
                      )
                    ])
                  ]
                )
              }),
              0
            )
          ]
        ),
        _c("skynode-confirmation-modal", {
          ref: "SkynodeConfirmationModal",
          attrs: { size: "small" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "th",
      {
        staticClass: "sticky top-0 w-auto px-5 py-1 text-left bg-gray-50",
        attrs: { scope: "col" }
      },
      [_c("span", { staticClass: "cursor-pointer" }, [_vm._v(" Permission ")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }