var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("modal", { attrs: { size: _vm.size }, on: { close: _vm.close } }, [
        _c("div", { staticClass: "p-4 px-8" }, [
          _c("div", { staticClass: "flex justify-between mb-4" }, [
            _c("div", { staticClass: "text-3xl capitalize" }, [
              _vm._v(" Update Skynode Permissions ")
            ]),
            _c(
              "div",
              {
                staticClass: "text-2xl cursor-pointer",
                on: { click: _vm.cancel }
              },
              [_vm._v("X")]
            )
          ]),
          _vm.node.allow
            ? _c("h4", [
                _vm._v(
                  " Are you sure you want to DENY this Skynode? It will be denied all further connections to Skyhub. "
                )
              ])
            : _vm._e(),
          !_vm.node.allow
            ? _c("h4", [
                _vm._v(
                  " Are you sure you want to ALLOW this Skynode? It will be allowed to connect to Skyhub until it is denied again. "
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "flex flex-row-reverse mt-4" }, [
            _c("div", { staticClass: "space-x-8" }, [
              _c(
                "button",
                {
                  staticClass: "skyhub-button",
                  attrs: { type: "button" },
                  on: { click: _vm.cancel }
                },
                [_vm._v(" Cancel ")]
              ),
              _c(
                "button",
                {
                  staticClass:
                    "px-4 py-2 text-gray-100 bg-gray-900 rounded font-roboto hover:bg-red-600",
                  attrs: { type: "button" },
                  on: { click: _vm.confirm }
                },
                [_vm._v(" Confirm ")]
              )
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }