enum PackagePlatformType {
  UNKNOWN,
  SWAK_KIT,
  GATEWAY
}

const PackagePlatformLabels = new Map<number, string>([
  [PackagePlatformType.UNKNOWN, 'Unknown Device'],
  [PackagePlatformType.SWAK_KIT, 'SWAK End User Device'],
  [PackagePlatformType.GATEWAY, 'Tactical Gateway']
]);

export { PackagePlatformType, PackagePlatformLabels };
