enum PackageIconColor {
  GRAY,
  RED,
  ORANGE,
  BLUE,
  PURPLE,
  GREEN,
  TEAL,
  PINK,
  INDIGO,
  YELLOW
}

const PackageIconColorLabels = new Map<number, string>([
  [PackageIconColor.GRAY, 'Gray'],
  [PackageIconColor.RED, 'Red'],
  [PackageIconColor.ORANGE, 'Orange'],
  [PackageIconColor.BLUE, 'Blue'],
  [PackageIconColor.PURPLE, 'Purple'],
  [PackageIconColor.GREEN, 'Green'],
  [PackageIconColor.TEAL, 'Teal'],
  [PackageIconColor.PINK, 'Pink'],
  [PackageIconColor.INDIGO, 'Indigo'],
  [PackageIconColor.YELLOW, 'Yellow']
]);

const PackageIconTextColor = new Map<number, string>([
  [PackageIconColor.GRAY, 'text-gray-500'],
  [PackageIconColor.RED, 'text-red-500'],
  [PackageIconColor.ORANGE, 'text-yellow-500'],
  [PackageIconColor.BLUE, 'text-blue-500'],
  [PackageIconColor.PURPLE, 'text-purple-500'],
  [PackageIconColor.GREEN, 'text-green-500'],
  [PackageIconColor.TEAL, 'text-green-300'],
  [PackageIconColor.PINK, 'text-pink-500'],
  [PackageIconColor.INDIGO, 'text-indigo-500'],
  [PackageIconColor.YELLOW, 'text-yellow-200']
]);

const PackageIconBGColor = new Map<number, string>([
  [PackageIconColor.GRAY, 'bg-gray-500'],
  [PackageIconColor.RED, 'bg-red-500'],
  [PackageIconColor.ORANGE, 'bg-yellow-500'],
  [PackageIconColor.BLUE, 'bg-blue-500'],
  [PackageIconColor.PURPLE, 'bg-purple-500'],
  [PackageIconColor.GREEN, 'bg-green-500'],
  [PackageIconColor.TEAL, 'bg-green-300'],
  [PackageIconColor.PINK, 'bg-pink-500'],
  [PackageIconColor.INDIGO, 'bg-indigo-500'],
  [PackageIconColor.YELLOW, 'bg-yellow-200']
]);

export {
  PackageIconColor,
  PackageIconColorLabels,
  PackageIconTextColor,
  PackageIconBGColor
};
