
















import { Component, Prop, Vue } from 'vue-property-decorator';
@Component<ModalComponent>({})
export default class ModalComponent extends Vue {
  @Prop({ default: '' })
  readonly size!: string;
  get width() {
    switch (this.size) {
      case 'small':
        return 'w-450';
      case 'medium':
        return 'w-650';
      case 'large':
        return 'w-850';
      default:
        return 'w-1/2';
    }
  }
  private closeHandler() {
    this.$emit('close');
  }
}
