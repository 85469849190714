enum PackageIcon {
  NONE,
  ROCKET,
  FAN,
  WRENCH,
  FILE
}

const PackageIconLabels = new Map<number, string>([
  [PackageIcon.NONE, 'None'],
  [PackageIcon.ROCKET, 'Rocket'],
  [PackageIcon.FAN, 'Fan'],
  [PackageIcon.WRENCH, 'Wrench'],
  [PackageIcon.FILE, 'File'],
]);

const PackageIconCss = new Map<number, string>([
  [PackageIcon.NONE, 'none'],
  [PackageIcon.ROCKET, 'rocket'],
  [PackageIcon.FAN, 'fan'],
  [PackageIcon.WRENCH, 'wrench'],
  [PackageIcon.FILE, 'file'],
]);

export { PackageIcon, PackageIconLabels, PackageIconCss};
