var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center bg-black bg-opacity-50",
      on: {
        click: _vm.closeHandler,
        keyup: function($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closeHandler($event)
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "relative z-50 max-w-screen-lg min-w-full bg-white rounded shadow sm:min-w-0",
          class: _vm.width,
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }