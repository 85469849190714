var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class:
        "flex items-center justify-center overflow-hidden rounded shadow-lg " +
        _vm.backgroundColors.get(_vm.backgroundColor)
    },
    [
      _vm.icon != 0
        ? _c("font-awesome-icon", {
            attrs: { icon: ["fa", _vm.iconCss.get(_vm.icon)] }
          })
        : _c(
            "div",
            { staticClass: "uppercase", attrs: { "data-cy": "noneIcon" } },
            [_vm._v(" " + _vm._s(_vm.packageFamily.charAt(0)) + " ")]
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }